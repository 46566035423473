import React, {FC} from "react";
import logo02 from '../../../img/logo02.png'
import './thumbnail.css'

type Data = {
    img: string;
    title: string;
    subTitle: string;
    setActiveModal: ()=>void;
}

const Thumbnail:FC<Data> = (props) =>{
    const {img, title, subTitle, setActiveModal} = props;
    const change = subTitle === 'Компьютерная диагностика, ремонт электрики, изготовление РВД на заказ';
    return(
        <div className={'container'}>
            <div>
                <img src={img} alt="Nature" className={'container-img'}/>
                <div className={'container-text'}>
                    <div className={'container-text-feedback bold'}>
                        <div className={'container-text-feedback-mobile'}><a className="mobile-link" href='tel:+79998887766'>+7(966)080-10-18</a></div>
                        <div className="container-logo">
                            <img className="logo" src={logo02} alt="logo" />
                        </div>
                        <div className={'container-text-feedback-email'} onClick={setActiveModal}>rvdservice24@yandex.rud</div>
                    </div>
                    <div className={'container-text-content bold'}>
                        <div className={'container-text-content-title'}>
                            <h1 className={'container-text-content-title-h1'}>{title}</h1>
                        </div>
                        <div className={'container-text-content-subtitle'}>
                            {subTitle[0]}
                        </div>
                        <div className={'container-text-content-subtitle'}>
                            {subTitle[1]}
                        </div>
                        <div className={'container-text-content-subtitle'}>
                            {subTitle[2]}
                        </div>
                        <div className={'container-text-content-subtitle'}>
                            {subTitle[3]}
                        </div>
                        <div className={'container-text-content-subtitle'}>
                            {subTitle[4]}
                        </div>
                        <div className={'container-text-content-subtitle'}>
                            {subTitle[5]}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default Thumbnail;

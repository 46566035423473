const Reviews = [
    {
        name: 'ilya.rog32',
        stars: 5,
        reviews: 'Быстро разобрались в автопроводке и устранили КЗ',
    },
    {
        name: 'Кристина Дахнова',
        stars: 5,
        reviews: 'Оперативно отреагировали, все сделали качественно, обязательно обращусь еще раз',
    },
    {
        name: 'Светлана Ребрина',
        stars: 5,
        reviews: 'Квалифицированный персонал. На месте изготовили по образцу. Работают быстро и качественно. Спасибо! Будем сотрудничать.',
    },
    {
        img: '',
        name: 'Татьяна Вдовина',
        stars: 5,
        reviews: 'Отличный сервис, быстро изготовили и все привезли в максимально короткий срок. Рекомендую.',
    },
    {
        name: 'Оленька P.',
        stars: 5,
        reviews: 'Обращались в компанию, быстро все сделали. Качество супер. Будем сотрудничать.',
    },
    {
        name: 'Ирина Б.',
        stars: 5,
        reviews: 'Работаем с ними давно, все не доходили руки написать отзыв, просто спасибо, с вами всегда все четко.',
    },
    {
        name: 'Веньямин Веньяминович',
        stars: 5,
        reviews: 'Обращались несколько раз, всегда есть все комплектующие, оперативно помогли, спасибо.',
    },
    {
        name: 'Анна',
        stars: 5,
        reviews: 'Очень быстро, качественно и недорого, рекомендую. Ребята молодцы.',
    },
    {
        name: 'Таисия К.',
        stars: 5,
        reviews: 'хороший сервис, очень довольны, быстро приехали, будем сотрудничать',
    },
    {
        name: 'Маргарита',
        stars: 5,
        reviews: 'Всё четко, быстро и качественно. Спасибо за вашу работу👍',
    },
    {
        name: 'Евгений',
        stars: 5,
        reviews: 'Мастера своего дела. Ребята большие молодцы, так держать!',
    },
    {
        name: 'liza fox',
        stars: 5,
        reviews: 'профессиональные ребята, сделали все быстро',
    },
    {
        name: 'Руслан М',
        stars: 5,
        reviews: 'Всё чётко мастера своего дела',
    },
]

export default Reviews;

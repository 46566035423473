import img0 from '../../img/Thumbnail/1.png'
// import img1 from '../../img/Thumbnail/thumbnail1.png'
// import img2 from '../../img/Thumbnail/thumbnail2.png'
// import img3 from '../../img/Thumbnail/thumbnail3.png'
// import img4 from '../../img/Thumbnail/thumbnail4.png'

const Data = [
    {
        img: img0,
        title:'РВД сервис 24',
        subTitle:['Компьютерная диагностика, ремонт электрики, изготовление РВД на заказ' ,
         'Диагностика неполадок с помощью специализированного оборудования',
         'Ремонт электрики грузовых и легковых автомобилей, а также спец техники',
         'Производство, продажа, ремонт, обслуживание рукавов высокого давления',
         'Изготовление рукавов на заказ, с учетом требований заказчика'
        ]

    }
    // {
    //     img: img3,
    //     title:'Компьютерная диагностика',
    //     subTitle:'Диагностика неполадок с помощью специализированного оборудования',
    // },
    // {
    //     img: img2,
    //     title:'Ремонт электрики',
    //     subTitle:'Грузовых и легковых автомобилей, а также спец техники',
    // },
    // {
    //     img: img1,
    //     title:'РВД сервис 24',
    //     subTitle:'Производство, продажа, ремонт, обслуживание рукавов высокого давления',
    // },
    // {
    //     img: img4,
    //     title:'Изготовление РВД',
    //     subTitle:'Изготовление рукавов на заказ, с учетом требований заказчика',
    // },
    // {
    //     img: img1,
    //     title:'РВД сервис 24',
    //     subTitle:'Производство, продажа, ремонт, обслуживание рукавов высокого давления',
    // },
    // {
    //     img: img3,
    //     title:'Компьютерная диагностика',
    //     subTitle:'Диагностика неполадок с помощью специализированного оборудования',
    // },
]
export default Data;

import React, {FC} from "react";
import ReactStars from "react-rating-stars-component";
import './reviewsitem.css'
type Reviews = {
    name: string,
    stars: number,
    reviews: string
}

const ReviewsItemMobFive: FC<Reviews> = (props) =>{
    const {name, stars, reviews} = props;
    return (
        <div className={'reviewsitem bold'}>
            <div className={'reviewsitem-name'}>
                {name}
            </div>
        
            <div className={'reviewsitem-reviews'}>
                {reviews}
            </div>
        </div>
    );
}

export default ReviewsItemMobFive;
